<section data-aos="fade-up" data-aos-duration="1000">
  <div class="left">
    <h2>{{ "workTogether" | translate }}</h2>
  </div>
  <div class="right">
    <span>{{ "aboutMe-1" | translate }}</span>
    <span>{{ "aboutMe-2" | translate }}</span>
    <div class="send-message-button">
      <a href="#contact" class="fill-button">{{ "sendMessage" | translate }}</a>
    </div>
  </div>

  <div class="ball-big">
    <img src="./assets/img/background/ball.svg" alt="Ball big" />
  </div>

  <div class="ball-small">
    <img src="./assets/img/background/ball.svg" alt="Ball small" />
  </div>
</section>
