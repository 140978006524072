<section>
  <div class="site-wrapper">
    <nav>
      <a href="https://christian-grund.dev/"><p class="logo">&lt;/CG&gt;</p></a>

      <div class="language-change-middle">
        <div class="language-change">
          <div class="language-link-wrapper">
            <a
              (click)="changeLanguage('de')"
              [ngClass]="{ active: selectedLanguage === 'de' }"
              >DE</a
            >
            <div
              class="underline-language"
              [ngClass]="{ active: selectedLanguage === 'de' }"
            ></div>
          </div>
          <p>|</p>
          <div class="language-link-wrapper">
            <a
              (click)="changeLanguage('en')"
              [ngClass]="{ active: selectedLanguage === 'en' }"
              >EN</a
            >
            <div
              class="underline-language"
              [ngClass]="{ active: selectedLanguage === 'en' }"
            ></div>
          </div>
        </div>
      </div>

      <div class="links">
        <div class="link-wrapper">
          <a (click)="toggleUnderline(1)" href="#about_me">{{
            "about" | translate
          }}</a>
          <div class="underline" [class.active]="underlineActive === 1"></div>
        </div>

        <div class="link-wrapper">
          <a (click)="toggleUnderline(2)" href="#skill_set" class="font-size"
            >Skills</a
          >
          <div class="underline" [class.active]="underlineActive === 2"></div>
        </div>

        <div class="link-wrapper">
          <a (click)="toggleUnderline(3)" href="#portfolio">Portfolio</a>
          <div class="underline" [class.active]="underlineActive === 3"></div>
        </div>

        <div class="language-change-right">
          <div class="language-change">
            <div class="language-link-wrapper">
              <a
                (click)="changeLanguage('de')"
                [ngClass]="{ active: selectedLanguage === 'de' }"
                >DE</a
              >
              <div
                class="underline-language"
                [ngClass]="{ active: selectedLanguage === 'de' }"
              ></div>
            </div>
            <p>|</p>
            <div class="language-link-wrapper">
              <a
                (click)="changeLanguage('en')"
                [ngClass]="{ active: selectedLanguage === 'en' }"
                >EN</a
              >
              <div
                class="underline-language"
                [ngClass]="{ active: selectedLanguage === 'en' }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="menu">
        <div class="box">
          <div
            class="btn"
            (click)="toggleMenu(); toggleButton()"
            [ngClass]="{ active: buttonActive, 'not-active': !buttonActive }"
          >
            <span [ngClass]="{ 'icon-active': buttonActive }"></span>
            <span [ngClass]="{ 'icon-active': buttonActive }"></span>
            <span [ngClass]="{ 'icon-active': buttonActive }"></span>
          </div>
        </div>
      </div>
    </nav>

    <div class="overlay" [class.overlay-active]="isOverlayActive">
      <div class="mobile-links">
        <a href="#about_me" (click)="toggleMenu(); toggleButton()">{{
          "about" | translate
        }}</a>
        <a href="#skill_set" (click)="toggleMenu(); toggleButton()">Skills</a>
        <a href="#portfolio" (click)="toggleMenu(); toggleButton()"
          >Portfolio</a
        >
      </div>
    </div>
  </div>
</section>

<section class="overlay-rotate-device">
  <img
    src="./assets/img/icons/rotate_device/rotate_device.svg"
    alt="rotate-device"
  />
  <p>Please Rotate Device</p>
</section>
