<div class="page-wrapper">
  <div class="data-privacy">
    <h1 class="dp-h1">{{ "privacyPolicy-h1" | translate }}</h1>
    <p class="dp-p">
      {{ "privacyPolicy-p" | translate
      }}<a href="mailto:mail@christian-grund.dev"
        >mail&#64;christian-grund.dev</a
      >.
    </p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-collection" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-collection" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-cookies" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-cookies" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-logfiles" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-logfiles" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-analytics" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-analytics" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-datasharing" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-datasharing" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-changes" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-changes" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-externallinks" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-externallinks" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-security" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-security" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-changes" | translate }}</h2>
    <p class="dp-p">{{ "privacyPolicy-p-changes" | translate }}</p>

    <h2 class="dp-h2">{{ "privacyPolicy-h2-contact" | translate }}</h2>
    <p class="dp-p">
      {{ "privacyPolicy-p-contact" | translate }}
      <a href="mailto:mail@christian-grund.dev">mail&#64;christian-grund.dev</a
      >.
    </p>
    <br /><br />

    <p class="dp-p">{{ "privacyPolicy-p-date" | translate }}</p>

    <div class="go-back">
      <a routerLink="/home" class="go-back-link"></a>
    </div>

    <div class="go-up">
      <a (click)="scrollToTop()" class="go-up-link"></a>
    </div>
  </div>
</div>
