<section>
  <div class="main">
    <h3>{{ "contact" | translate }}</h3>

    <div class="wrapper">
      <div class="text">
        <h6>{{ "problemToSolve" | translate }}</h6>
        <p>{{ "contactMe" | translate }}</p>
        <p>
          {{ "needFD" | translate }}<span>{{ "contactMe2" | translate }}</span>
        </p>
      </div>

      <form
        (ngSubmit)="onSubmit(contactForm)"
        #contactForm="ngForm"
        class="form"
      >
        <!-- Name -->
        <div class="input-container">
          <input
            #name="ngModel"
            [(ngModel)]="contactData.name"
            type="text"
            id="name"
            name="name"
            minlength="2"
            [ngClass]="{
              error: !name.valid && name.touched,
              success: name.valid && name.touched
            }"
            required
            (focus)="nameIsFocused = true"
            (blur)="nameIsFocused = false"
          />
          <label
            class="error-message-top"
            [class.visible]="nameIsFocused || contactData.name"
            >{{ "enterName" | translate }}</label
          >

          <span class="error-message" *ngIf="!name.valid && name.touched">{{
            "enterNameError" | translate
          }}</span>
        </div>

        <!-- Email -->
        <div class="input-container">
          <input
            #email="ngModel"
            [(ngModel)]="contactData.email"
            type="email"
            id="email"
            name="email"
            [ngClass]="{
              error: !email.valid && email.touched,
              success: email.valid && email.touched
            }"
            required
            pattern="^(http(s){0,1}:\/\/.){0,1}[\-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$"
            (focus)="emailIsFocused = true"
            (blur)="emailIsFocused = false"
          />
          <label
            class="error-message-top"
            [class.visible]="emailIsFocused || contactData.email"
            >{{ "enterMail" | translate }}</label
          >

          <span class="error-message" *ngIf="!email.valid && email.touched">{{
            "enterMailError" | translate
          }}</span>
        </div>

        <!-- Textarea -->
        <div class="textarea-container">
          <textarea
            #message="ngModel"
            [(ngModel)]="contactData.message"
            minlength="4"
            id="message"
            name="message"
            [ngClass]="{
              error: !message.valid && message.touched,
              success: message.valid && message.touched
            }"
            style="height: 200px"
            required
            (focus)="textareaIsFocused = true"
            (blur)="textareaIsFocused = false"
          ></textarea>
          <label
            class="error-message-top"
            [class.visible]="textareaIsFocused || contactData.message"
            >{{ "enterMessage" | translate }}
          </label>

          <span class="error-message" *ngIf="!message.valid && message.touched">
            {{ "enterMessageError" | translate }}
          </span>
        </div>

        <!-- Privacy policy checkbox -->
        <div
          class="checkbox-privacy-policy"
          (mouseenter)="hovered = true"
          (mouseleave)="hovered = false"
        >
          <div class="privacy-policy-wrapper">
            <input
              type="checkbox"
              id="checkboxPrivatPolicy"
              name="checkboxPrivatPolicy"
              class="checkbox-privacy-policy"
              required
              [(ngModel)]="privacyPolicyChecked"
              (click)="togglePrivacyPolicy()"
            />

            <label
              for="checkboxPrivatPolicy"
              [class.hovered]="hovered"
              (click)="toggleLabel()"
            ></label>

            <p class="accept-privacy-policy">
              {{ "iHaveRead" | translate }}
              <a routerLink="/data-privacy" (click)="scrollToTop()"
                ><span
                  [ngClass]="{
                    english: currentLanguage === 'en',
                    german: currentLanguage === 'de'
                  }"
                  >{{ "privacyPolicy" | translate }}</span
                ></a
              >
              {{ "iAgree" | translate }}
            </p>
          </div>

          <span
            class="error-message"
            *ngIf="clickCounter % 2 === 0 && clickCounter > 0"
          >
            {{ "acceptPrivacyPolicyError" | translate }}
          </span>
        </div>

        <!-- Submit button -->
        <div class="submit-btn">
          <button
            [ngClass]="{
              'button-disabled': !contactForm.form.valid,
              'fill-button': contactForm.form.valid
            }"
            [disabled]="!contactForm.form.valid"
          >
            {{ "sayHello" | translate }}
          </button>
        </div>

        <div class="mail-success" [ngClass]="{ show: isContainerVisible }">
          <div class="bg-img">
            <div class="success-text">
              <p>Thank you!</p>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="ball-big">
      <img src="./assets/img/background/ball.svg" alt="Ball big" />
    </div>
    <div class="ball-small">
      <img src="./assets/img/background/ball.svg" alt="Ball small" />
    </div>

    <div class="go-up">
      <a href="#landing_page" class="go-up-link"></a>
    </div>
  </div>
</section>
