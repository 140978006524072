<section>
  <div class="main">
    <div class="aot-right">
      <div class="headline">
        <h1
          class="headline-h1"
          [ngClass]="{
            english: currentLanguage === 'en',
            german: currentLanguage === 'de'
          }"
        >
          {{ "helloIAm" | translate }}
        </h1>

        <h2 class="typewrite">
          <span class="text_1">FRONTEND</span>
          <span class="text_2">BACKEND</span>
          <span class="text_3">FULLSTACK</span>
        </h2>

        <h2
          class="headline-h2"
          [ngClass]="{
            english: currentLanguage === 'en',
            german: currentLanguage === 'de'
          }"
        >
          {{ "developer" | translate }}
        </h2>
      </div>
    </div>

    <div class="circle">
      <img src="./assets/img/background/ball.svg" alt="Ball" />
    </div>

    <div class="social-icons">
      <a href="https://github.com/christian-grund/" target="_blank"
        ><img src="./assets/img/icons/social/github.svg" alt="Github"
      /></a>
      <a href="mailto:mail@christian-grund.dev"
        ><img src="./assets/img/icons/social/mail.svg" alt="Mail"
      /></a>
      <a
        href="https://www.linkedin.com/in/christian-grund-b77840329/"
        target="_blank"
        ><img src="./assets/img/icons/social/linkedin.svg" alt="LinkedIn"
      /></a>
    </div>

    <div class="scroll">
      <span>{{ "scroll" | translate }} </span>
      <div></div>
    </div>

    <div class="portrait">
      <img src="./assets/img/aot/portrait.png" alt="Portrait" />
    </div>
  </div>
</section>
