<div class="imprint">
  <div class="main">
    <h1>{{ "imprint" | translate }}</h1>
    <p>{{ "accordingTo" | translate }}</p>
    <p>
      Christian Grund<br />
      Fritz-Tröndle-Str. 6a<br />
      77704 Oberkirch <br /><br />
    </p>
    <p class="p-blue">
      {{ "represented" | translate }}
    </p>
    Christian Grund<br /><br />

    <p class="p-blue">{{ "contact" | translate }}:</p>
    <p>
      Email:
      <a href="mailto:mail@christian-grund.dev">mail&#64;christian-grund.dev</a>
    </p>
    <br /><br />

    <h2>{{ "disclaimer" | translate }}</h2>

    <h3>{{ "l-content" | translate }}</h3>

    <p>
      {{ "l-content-text" | translate }}
    </p>

    <h3>{{ "l-links" | translate }}</h3>

    <p>{{ "l-links-text" | translate }}</p>

    <h3>{{ "copyright" | translate }}</h3>
    <p>{{ "copyright-text" | translate }}</p>

    <h3>{{ "data-protection" | translate }}</h3>
    <p>
      {{ "data-protection-text-1" | translate }}<br />
      {{ "data-protection-text-2" | translate }}<br />
      {{ "data-protection-text-3" | translate }}
    </p>
    <br /><br />

    <p>
      {{ "imprintFrom" | translate }}
      <a
        href="https://www.impressum-generator.de"
        target="_blank"
        rel="nofollow"
        >{{ "imprintGenerator" | translate }}</a
      >
      {{ "of" | translate }}
      <a
        href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/"
        rel="nofollow"
        target="_blank"
        >{{ "lawFirm" | translate }}</a
      >
    </p>

    <div class="go-back">
      <a routerLink="/home" class="go-back-link"></a>
    </div>

    <div class="go-up">
      <a (click)="scrollToTop()" class="go-up-link"></a>
    </div>
  </div>
</div>
